import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Container from 'components/Container'
import MedKitIcon from 'components/icons/MedKit'
import { Title } from 'components/Page'
import painImg from 'static/pain.jpg'
import IconContainer from 'components/icons/Container'

const Description = props => (
  <p
    className="text-lg text-center md:text-left leading-normal mb-8"
    {...props}
  />
)

const Subheader1 = props => (
  <p className="text-open-blue text-2xl text-center my-8" {...props} />
)

const Subheader2 = props => (
  <p className="text-red text-2xl text-center my-8" {...props} />
)

const DescriptionHeader = props => (
  <p
    className="block text-orange text-lg text-center md:text-left leading-normal mb-2"
    {...props}
  />
)

const Content = props => (
  <div className="flex flex-col md:flex-row px-4" {...props} />
)

const BottomContent = props => <div className="py-8" {...props} />

const ImageBox = ({ imageSrc, ...props }) => (
  <div
    className="rounded bg-center bg-no-repeat shadow-xl"
    style={{ backgroundImage: `url(${imageSrc})` }}
    {...props}
  />
)

const EmergencyCarePage = () => (
  <Layout>
    <Helmet title="Oregon City Smiles - Emergency Care" />
    <Container>
      <Title>
        <IconContainer color="red">
          <MedKitIcon color="white" size="2rem" />
        </IconContainer>
        Emergency Care
      </Title>
      <Subheader1>
        AFTER HOURS EMERGENCY TREATMENT “After Hours” or “Is it an emergency?”
      </Subheader1>
      <Subheader2>NO PAIN TO MODERATE PAIN</Subheader2>
      <Content>
        <div className="flex-1 md:mr-12 mb-4">
          <Description>
            <DescriptionHeader>Loose Crown</DescriptionHeader>
            It is normal to have tooth sensitivity if your crown came off loose.
            If you are not in moderate to severe pain, keep the area clean, and
            avoid eating on that side. Leave a message on our general answering
            machine and arrive at the office first thing the next business day.
            Please save your crown and bring it with you to your appointment in
            case it can be re-cemented.
          </Description>
          <Description>
            <DescriptionHeader>
              Throbbing tooth and/or swelling
            </DescriptionHeader>
            If pain is mild to moderate, take 800mg Ibuprofen up to 3 times a
            day. And please call and leave a message with our answering machine
            and arrive at the office first thing the next working day.
          </Description>
          <Description>
            <DescriptionHeader>Sore Mouth</DescriptionHeader>
            Rinse with warm salt water, avoid alcohol, carbonation and spicy
            foods. Please call 503-518-3384 and schedule an appointment to be
            seen.
          </Description>
        </div>

        <div className="flex-1 mb-4">
          <Description>
            <DescriptionHeader>Broken Tooth</DescriptionHeader>
            If you are not in pain, please keep area clean. Please call
            503-518-3384 and leave a message and we will call you back to
            schedule an appointment and/or arrive at 8am the next open business
            day and we will work you in.
          </Description>
          <Description>
            <DescriptionHeader>Loose or broken filling</DescriptionHeader>
            If you are not in pain, please keep area clean. Please call
            503-518-3384 and leave a message and we will call you back to
            schedule an appointment or leave a message and arrive at 8am the
            next open business day and we will work you in. If you have mild to
            moderate pain, you can get over the counter temporary dental fill or
            dental wax from the pharmacy or grocery store for immediate relief.
          </Description>
          <Description>
            <DescriptionHeader>Recent Extraction pain</DescriptionHeader>
            It is common for a recent extraction area to be sore and even have
            moderate pain. This can continue for 3-5 days. This is due to
            swelling after the extraction from the body’s natural healing
            process. Please take either 800mg Ibuprofen up to 3x per day or
            500mg Tylenol 4x per day.
          </Description>
        </div>
      </Content>

      <Subheader2>SEVERE PAIN</Subheader2>
      <Content>
        <div className="flex-1 md:mr-12">
          <Description>
            If you are in severe pain, it is the weekend, and Ibuprofen is not
            helping, and you are a patient of record with the Oregon City Smiles
            Dental, formerly Glenn Family Dental, then you may call our after
            hours line. Leave a detailed message with type of pain, location in
            mouth and duration. Someone will get back to you as soon as they
            can. If a doctor has to come in after hours, there will be an
            emergency fee.
          </Description>
        </div>
        <div className="flex-1">
          <ImageBox imageSrc={painImg} />
        </div>
      </Content>
      <BottomContent>
        <Subheader1>POST SURGICAL CARE</Subheader1>
        <Description>
          After your surgery, it is important for your body’s natural blood clot
          to form and remain to initiate the healing process. Please leave your
          gauze in for 30-45 minutes before removing it. You should not have to
          replace with new gauze unless you feel bleeding. If you do replace
          with new gauze, dampen the gauze with either a couple drips of water
          to prevent from sticking. For the next 72 hours, please follow these
          instructions to allow proper healing and minimize delays and/or
          complications:
          <ul className="list-disc pl-8 my-4">
            <li>Do not disturb the surgical site</li>
            <li>Do not rinse vigorously</li>
            <li>Do not suck on straws</li>
            <li>Do not smoke</li>
            <li>Do not drink alcohol</li>
            <li>Do not drink carbonated beverages</li>
            <li>Do not drink or eat anything that is hot</li>
            <li>Do not eat spicy foods</li>
            <li>
              Do not brush teeth next to surgical site (for the first 24 hours)
            </li>
            <li>Do not participate in vigorous exercise</li>
            <li>Do not spit</li>
          </ul>
          If you want to gently rinse, then lean over sink and drool or whip
          mouth, again do not spit. It is common to experience mild to moderate
          pain and swelling (can last for 48-72 hours). If you were prescribed
          medications, please use as directed. If you had a bone graft done, you
          may feel small particles in your mouth from time to time. This is
          normal. If you had stiches, please leave them alone as best as you
          can. Drink Lots of fluids and keep to a soft nutritious diet. This can
          included smoothies, ice cream, mashed potatoes, and warm soups. If
          after 72 hours, you are noticing increased pain and/or swelling,
          please call the office at 503-518-3384 and ask to speak with a dental
          assistant to go over possible complications.
        </Description>
        <Description>
          Emergency Care policies generally cover only a portion of the total
          treatment cost. Unless other arrangements have been made, we ask that
          you pay your portion of the bill at the time of treatment. It is your
          responsibility to pay any balance not paid by your insurance company.
        </Description>
      </BottomContent>
    </Container>
  </Layout>
)

export default EmergencyCarePage
