import React from 'react'
import font from 'styles/font'

const containerSize = ({ size }) => font.size[size] || size || '3.5rem'

const Container = ({ color = 'open-blue', opacity, ...props }) => (
  <div
    className={`flex rounded-full justify-center items-center mx-auto mb-2 bg-${color}`}
    style={{
      width: containerSize(props),
      height: containerSize(props),
      opacity
    }}
    {...props}
  />
)

export default Container
