import React from 'react'
import IoniconSVG from './IoniconSVG'

const MedKit = props => (
  <IoniconSVG
    fill="currentColor"
    viewBox="0 0 512 512"
    strokeWidth={0}
    {...props}
  >
    <title>med kit</title>
    <path d="M352 144v-39.6C352 82 334 64 311.6 64H200.4C178 64 160 82 160 104.4V144H48v263.6C48 430 66 448 88.4 448h335.2c22.4 0 40.4-18 40.4-40.4V144H352zm-152-40h112v40H200v-40zm136 224h-56v56h-48v-56h-56v-48h56v-56h48v56h56v48z" />
  </IoniconSVG>
)

export default MedKit
